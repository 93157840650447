import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const StartHero = () => {
  return (
    <React.Fragment>
      <>
        {/* Banner-Section-Start */}
        <section className="banner_section">
          {/* container start */}
          <div className="container">
            {/* vertical animation line */}
            <div className="anim_line">
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
              <span>
                <img src="assets/images/anim_line.png" alt="anim_line" />
              </span>
            </div>
            {/* row start */}
            <div className="row">
              <div className="col-lg-6 col-md-12">
                {/* banner text */}
                <div className="banner_text">
                  {/* h1 */}
                  <h1>
                    Instant Mobile Recharge at Your <span>Fingertips!</span>
                  </h1>
                  {/* p */}
                  <p>
                    Recharge your mobile and dth in seconds with our trusted and
                    user-friendly recharge service. From prepaid to postpaid and
                    DTH, get the best offers and instant service, anytime,
                    anywhere. Recharge now and stay powered up!
                  </p>
                </div>
                {/* app buttons */}
                <ul className="app_btn">
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.fispl.jamcpay"
                      target="_blank"
                    >
                      <img
                        className="blue_img"
                        src="assets/images/googleplay_blue.png"
                        alt="image"
                      />
                      <img
                        className="white_img"
                        src="assets/images/googleplay_white.png"
                        alt="image"
                      />
                    </a>
                  </li>
                </ul>
                {/* users */}
                {/* <div className="used_app">
                  <ul>
                    <li>
                      <img src="assets/images/used01.png" alt="image" />
                    </li>
                    <li>
                      <img src="assets/images/used02.png" alt="image" />
                    </li>
                    <li>
                      <img src="assets/images/used03.png" alt="image" />
                    </li>
                    <li>
                      <img src="assets/images/used04.png" alt="image" />
                    </li>
                  </ul>
                  <p>
                    12M + <br /> used this app
                  </p>
                </div> */}
              </div>
              {/* banner slides start */}
              <div className="col-lg-6 col-md-12">
                <div>
                  <img
                    className="img"
                    src={"images/recharge-banner.png"}
                    width={600}
                  />
                </div>

                {/* <div className="banner_slider">
                  <div className="left_icon">
                    <img src="assets/images/message_icon.png" alt="image" />
                  </div>
                  <div className="right_icon">
                    <img src="assets/images/shield_icon.png" alt="image" />
                  </div>
                  <div id="frmae_slider" className="owl-carousel owl-theme">
                    <div className="item">
                      <div className="slider_img">
                        <img src="assets/images/screen.png" alt="image" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slider_img">
                        <img src="assets/images/screen.png" alt="image" />
                      </div>
                    </div>
                    <div className="item">
                      <div className="slider_img">
                        <img src="assets/images/screen.png" alt="image" />
                      </div>
                    </div>
                  </div>
                  <div className="slider_frame">
                    <img src="assets/images/mobile_frame_svg.svg" alt="image" />
                  </div>
                </div> */}
              </div>
              {/* banner slides end */}
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </section>
        {/* Banner-Section-end */}
      </>
    </React.Fragment>
  );
};

export default StartHero;
