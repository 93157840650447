import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const RefundPolicy = () => {
  useEffect(() => {
    document.title = "Refund Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* BredCrumb-Section */}
        <div className="bred_crumb">
          <div className="container">
            {/* shape animation  */}
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>
            <div className="bred_text">
              <h1>Refund Policy</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="refund_policy">Refund Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          {/* Blog Details Block */}
          <section className="blog_detail_section">
            <div className="container">
              <div className="blog_inner_pannel">
                <div className="section_title">
                  <h4>Refund Policy for JAY AMBEY MAA COM (JAMC PAY)</h4>
                </div>

                <div className="info">
                  <h5>1. Introduction</h5>
                  <p>
                    This Refund Policy outlines the terms and conditions under
                    which refunds are issued for transactions made through the
                    JAMC PAY. The policy ensures that all refund requests are
                    handled fairly, transparently, and efficiently.
                  </p>

                  <h5>2. Eligibility for Refund</h5>
                  <ul>
                    <li>
                      1. Refunds are applicable only for failed or unsuccessful
                      recharge transactions where the amount has been debited
                      from the user’s account but the recharge has not been
                      processed.
                    </li>
                    <li>
                      2. We will refund the amount in case the user has paid for
                      some services, got confirmation about the payment but does
                      not receive those services to utilize. In such cases, we
                      request the user to drop us a complaint on our official
                      Email ID and let us evaluate the scenario. Once we
                      investigate and make a conclusion, we will refund your
                      amount to your bank account
                    </li>
                    <li>
                      3. Refunds will not be provided for successful
                      transactions, services already rendered, or if the user
                      changes their mind after a recharge transaction has been
                      completed.
                    </li>
                  </ul>

                  <h5>3. Refund Request Process</h5>
                  <ul>
                    <li>
                      1. Users must submit a refund request within 7 from the
                      date of the transaction.
                    </li>
                    <li>
                      2. Refund requests can be submitted through the app’s
                      support section or by contacting customer service via
                      email/phone.
                    </li>
                    <li>
                      3. Users must provide transaction details, including the
                      transaction ID, date, and amount, along with a brief
                      description of the issue.
                    </li>
                  </ul>

                  <h5>4. Verification and Processing</h5>
                  <ul>
                    <li>
                      1. Once a refund request is received, the company will
                      verify the transaction details and the validity of the
                      refund claim.
                    </li>
                    <li>
                      2. The verification process may take up to 3-5 working
                      days.
                    </li>
                    <li>
                      3. Upon successful verification, the refund will be
                      processed, and the amount will be credited back to the
                      user’s account or original payment method.
                    </li>
                  </ul>

                  <h5>5. Refund Timeframe</h5>
                  <ul>
                    <li>
                      1. Refunds are typically processed within 3-5 working days
                      after verification.
                    </li>
                    <li>
                      2. The actual time it takes for the refunded amount to
                      reflect in the user’s account may vary depending on the
                      payment method and banking partner.
                    </li>
                  </ul>

                  <h5>6. Non-Refundable Situations</h5>
                  <ul>
                    <li>
                      1. No refund will be provided if the transaction was
                      successful, and the recharge service was delivered as
                      intended.
                    </li>
                    <li>
                      2. Refunds will not be issued if the user provides
                      incorrect information, leading to a failed transaction.
                    </li>
                    <li>
                      3. We will not be liable for any refund if the user fails
                      to perform KYC at the initial stage. Each user has to go
                      through a successful KYC verification once. Thus, it is
                      mandatory to keep the correct KYC documents ready before
                      initiating any payment to us.
                    </li>
                  </ul>

                  <h5>7. Dispute Resolution</h5>
                  <p>
                    In case of any disputes related to refunds, users can
                    escalate the matter to our customer support team. The
                    company will investigate and resolve disputes within 15
                    working days.
                  </p>

                  <h5>8. Policy Updates</h5>
                  <p>
                    JAMC PAY reserves the right to update this Refund Policy at
                    any time. Users will be informed of significant changes via
                    app notifications or email.
                  </p>

                  <h5>9. Contact Information</h5>
                  <p>
                    For refund-related inquiries, users can contact our support
                    team at +91 9852-5252-68 / jamcpay@gmail.com
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default RefundPolicy;
