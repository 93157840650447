import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const GrievancePolicy = () => {
  useEffect(() => {
    document.title = "Grievance Policy";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* BredCrumb-Section */}
        <div className="bred_crumb">
          <div className="container">
            {/* shape animation  */}
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>
            <div className="bred_text">
              <h1>Grievance Policy</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="terms_and_conditions">Grievance Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          {/* Blog Details Block */}
          <section className="blog_detail_section">
            <div className="container">
              <div className="blog_inner_pannel">
                <div className="section_title">
                  <h4>Grievance Policy for JAMC PAY</h4>
                </div>

                <div className="info">
                  <p>
                    In accordance with Information Technology Act 2000 and rules
                    made there under, contact details of the Grievance Officer
                    are provided below:
                  </p>
                  {/* <p>Name:</p> */}
                  <p>
                    Address: JAMC PAY, 187/B1, BISHUNPUR, PAKRI, PHULWARISARIF,
                    PATNA, BIHAR, 800002
                  </p>
                  {/* <p>Mobile:</p> */}
                  <p>Email: jamcpay@gmail.com</p>
                </div>
              </div>
            </div>
          </section>
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default GrievancePolicy;
