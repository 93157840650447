import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";
import ServicesBoxIcon from "./ServicesBoxIcon";

const OurServices = () => {
  useEffect(() => {
    document.title = "Our Services - JAMC PAY";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* BredCrumb-Section */}
        <div className="bred_crumb">
          <div className="container">
            {/* shape animation  */}
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>
            <div className="bred_text">
              <h1>Our Services</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="our_services">Our Services</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <section className="blog_detail_section">
          <div className="container">
            <div className="blog_inner_pannel">
              <div className="info">
                <div class="services">
                  <ServicesBoxIcon />

                  <div class="service">
                    <h3>Mobile Recharge</h3>
                    <p>
                      Stay connected without any interruptions with our seamless
                      mobile recharge services. We support all major telecom
                      operators, making it easy for you to recharge your prepaid
                      mobile number from anywhere, at any time. Whether you’re
                      at home, work, or on the go, our platform ensures a fast,
                      secure, and hassle-free recharge experience. With multiple
                      payment options and instant confirmation, keeping your
                      phone active has never been more convenient.
                    </p>
                  </div>

                  <div class="service">
                    <h3>DTH Recharge</h3>
                    <p>
                      Never miss your favorite shows again with our quick and
                      easy DTH recharge services. We cater to all leading DTH
                      service providers, ensuring that your TV entertainment
                      continues without any disruption. Simply select your DTH
                      provider, enter your subscriber details, and choose from a
                      variety of payment methods to complete the recharge. Enjoy
                      uninterrupted viewing with just a few taps, and keep your
                      entertainment flowing smoothly.
                    </p>
                  </div>

                  <div class="service">
                    <h3>Google Play Recharge</h3>
                    <p>
                      Unlock endless possibilities with our Google Play recharge
                      services. Whether you’re purchasing apps, games, movies,
                      or in-app items, our platform offers a simple way to top
                      up your Google Play balance. Just enter your account
                      details, select the amount you need, and complete the
                      payment securely. With instant delivery, you can dive into
                      your favorite digital content without delay, making sure
                      your entertainment and productivity needs are always met.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default OurServices;
