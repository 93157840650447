import React from "react";

import "./style.css";

const ServicesBoxIcon = () => {
  return (
    <section style={{ paddingTop: "70px" }}>
      <div class="container">
        <div
          class="section_title aos-init aos-animate"
          data-aos="fade-up"
          data-aos-duration="1500"
          data-aos-delay="300"
        >
          <h2>
            Our <span>Services</span>
          </h2>
          <p>
            Explore a wide range of recharge and payment solutions designed to
            <br />
            meet all your digital needs with speed, security, and convenience.
          </p>
        </div>

        <div class="row">
          <div class="col-md-4 col-sm-6">
            <div class="serviceBox pink">
              <div class="service-icon">
                <span>
                  <i class="fas fa-mobile"></i>
                </span>
              </div>
              <h3 class="title">Mobile Recharge</h3>
              <p class="description">
                JAMC PAY offers quick, secure mobile recharges for all major
                telecom operators in India, ensuring seamless connectivity
                anytime, anywhere.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="serviceBox pink">
              <div class="service-icon">
                <span>
                  <i class="fas fa-satellite-dish"></i>
                </span>
              </div>
              <h3 class="title">DTH Recharge</h3>
              <p class="description">
                JAMC PAY provides fast and easy DTH recharges for all major
                service providers, ensuring uninterrupted entertainment at your
                convenience.
              </p>
            </div>
          </div>
          <div class="col-md-4 col-sm-6">
            <div class="serviceBox pink">
              <div class="service-icon">
                <span>
                  <i class="fab fa-google-play"></i>
                </span>
              </div>
              <h3 class="title">Google Play Recharge</h3>
              <p class="description">
                JAMC PAY offers quick and secure Google Play recharges, giving
                you instant access to apps, games, and more, anytime you need.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBoxIcon;
