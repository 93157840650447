import React, { useEffect } from "react";
import Footer from "../Templete/Footer";
import Header from "../Templete/Header";

import Testimonials from "../Home/Testimonials";
import GetInTouch from "../Home/GetInTouch";

const AboutUs = () => {
  useEffect(() => {
    document.title = "About Us - JAMC PAY";
  }, []);

  return (
    <React.Fragment>
      <div class="page_wrapper">
        <Header headerType="white_header" />

        {/* BredCrumb-Section */}
        <div className="bred_crumb">
          <div className="container">
            {/* shape animation  */}
            <span className="banner_shape1">
              {" "}
              <img src="assets/images/banner-shape1.png" alt="image" />{" "}
            </span>
            <span className="banner_shape2">
              {" "}
              <img src="assets/images/banner-shape2.png" alt="image" />{" "}
            </span>
            <span className="banner_shape3">
              {" "}
              <img src="assets/images/banner-shape3.png" alt="image" />{" "}
            </span>
            <div className="bred_text">
              <h1>About Us</h1>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <span>»</span>
                </li>
                <li>
                  <a href="about_us">About Us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <>
          {/* App-Solution-Section-Start */}
          <section className="row_am app_solution_section">
            {/* container start */}
            <div className="container">
              {/* row start */}
              <div className="row">
                <div className="col-lg-12">
                  {/* UI content */}
                  <div className="app_text">
                    <div className="section_title">
                      <h2 style={{ textAlign: "center" }}>
                        About <span>JAMC PAY</span>
                      </h2>
                    </div>
                    <p style={{ textAlign: "justify" }}>
                      Welcome to <b>JAMC PAY</b>, your trusted partner in
                      seamless and efficient recharge solutions across India. We
                      are a dedicated platform designed to empower individuals,
                      small businesses, and entrepreneurs by providing a
                      reliable and user-friendly service for mobile, DTH, and
                      utility recharges. Our mission is to simplify the recharge
                      process, offering a fast, secure, and hassle-free
                      experience for our users.
                    </p>
                    <p style={{ textAlign: "justify" }}>
                      The journey of JAMC PAY began with a simple yet powerful
                      idea: to create a platform that bridges the gap between
                      technology and convenience in the recharge industry.
                      Founded by a group of forward-thinking professionals with
                      extensive experience in the digital and financial sectors,
                      we recognized the need for a reliable, easy-to-use
                      recharge service that caters to the diverse needs of the
                      Indian market. From our humble beginnings, we have grown
                      into a trusted name, serving thousands of satisfied users
                      nationwide.
                    </p>
                  </div>

                  <div
                    style={{
                      paddingTop: "25px",
                    }}
                  ></div>
                  <img
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src="images/about_us_2.png"
                    width={1000}
                  />
                </div>
              </div>
              {/* row end */}
              {/* row start */}
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-lg-6">
                  <img src="images/our-vision.png" width={350} />
                </div>
                <div className="col-lg-6">
                  {/* UI content */}
                  <div className="app_text">
                    <div className="section_title">
                      <h2>Our Vision</h2>
                    </div>
                    <p>
                      Our vision is to be the leading recharge platform in
                      India, known for our unwavering commitment to quality,
                      innovation, and customer satisfaction. We aspire to create
                      a digital ecosystem where recharges are not just
                      transactions, but a seamless and rewarding experience for
                      everyone involved. By continuously expanding our services
                      and embracing new technologies, we aim to stay ahead in an
                      ever-evolving market, offering solutions that make a
                      difference in the lives of our users.
                    </p>
                  </div>
                </div>
              </div>
              {/* row end */}
              {/* row start */}
              <div className="row" style={{ paddingTop: "25px" }}>
                <div className="col-lg-6">
                  {/* UI content */}
                  <div className="app_text">
                    <div className="section_title">
                      <h2>Our Mission</h2>
                    </div>
                    <p>
                      At JAMC PAY, our mission is to simplify the recharge
                      process while providing value-added services that enhance
                      the overall experience. We strive to empower our users by
                      offering a platform that is not only easy to use but also
                      provides the best commissions, rewards, and support. Our
                      focus is on building long-term relationships with our
                      users, partners, and stakeholders by consistently
                      delivering high-quality services that exceed expectations.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <img src="images/our-mission.png" width={400} />
                </div>
              </div>
              {/* row end */}
            </div>
            {/* container end */}
          </section>
          {/* App-Solution-Section-end */}

          <section
            className="row_am why_we_section aos-init aos-animate"
            data-aos="fade-in"
          >
            <div className="why_inner">
              <div className="container">
                <div
                  className="section_title aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={100}
                >
                  <h2>Why we are different from others!</h2>
                  <p>
                    At JAMC PAY, we stand out by offering comprehensive recharge
                    solutions, a user-friendly platform, and unmatched
                    reliability. Our competitive commissions, 24/7 support, and
                    advanced security ensure a seamless experience. We
                    prioritize innovation, transparency, and excellence, making
                    us the go-to choice for individuals and businesses alike.
                    Experience the difference with us today!
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="why_box aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                      data-aos-delay={100}
                    >
                      <div className="icon">
                        <img
                          src="assets/images/abt_functional.png"
                          alt="image"
                        />
                      </div>
                      <div className="text">
                        <h3>Comprehensive Recharge Solutions</h3>
                        <p>
                          Unlike many platforms that focus solely on specific
                          services, we provide a one-stop solution for all your
                          mobile and dth recharge needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="why_box aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                      data-aos-delay={200}
                    >
                      <div className="icon">
                        <img
                          src="assets/images/communication.png"
                          alt="image"
                        />
                      </div>
                      <div className="text">
                        <h3>Competitive Commissions</h3>
                        <p>
                          We believe in sharing success with our partners.
                          That’s why we offer some of the most competitive
                          commission rates in the industry.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="why_box aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                      data-aos-delay={300}
                    >
                      <div className="icon">
                        <img src="assets/images/secure.png" alt="image" />
                      </div>
                      <div className="text">
                        <h3>Advanced Security Measures</h3>
                        <p>
                          Your security is our top priority. We employ
                          state-of-the-art encryption and security protocols to
                          protect your data and transactions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-3">
                    <div
                      className="why_box aos-init aos-animate"
                      data-aos="fade-up"
                      data-aos-duration={1500}
                      data-aos-delay={400}
                    >
                      <div className="icon">
                        <img src="assets/images/abt_support.png" alt="image" />
                      </div>
                      <div className="text">
                        <h3>24/7 Dedicated Support</h3>
                        <p>
                          Exceptional customer service is one of our top
                          priorities. Our dedicated support team is available
                          around the clock to assist you with any queries or
                          issues.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="row_am experts_team_section">
            <div className="container">
              <div
                className="section_title aos-init aos-animate"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={100}
              >
                {/* h2 */}
                <h2>
                  {" "}
                  Meet our <span> experts </span>
                </h2>
                {/* p */}
                <p>
                  Our team is composed of skilled professionals dedicated to
                  delivering top-notch service and <br />
                  innovative solutions. Get to know the experts behind our
                  success!
                </p>
              </div>
              <div className="row">
                <div
                  className="col-md-6 col-lg-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={100}
                >
                  <div className="experts_box">
                    <img src="images/user-icon.png" alt="image" />
                    <div className="text">
                      <h3>Ravindra Kumar</h3>
                      <span>CEO &amp; Co-Founder</span>
                      <ul className="social_media">
                        <li>
                          <a href="#">
                            <i className="icofont-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={200}
                >
                  <div className="experts_box">
                    <img src="images/user-icon.png" alt="image" />
                    <div className="text">
                      <h3>Ram Kumar</h3>
                      <span>Business Development</span>
                      <ul className="social_media">
                        <li>
                          <a href="#">
                            <i className="icofont-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={300}
                >
                  <div className="experts_box">
                    <img src="images/user-icon.png" alt="image" />
                    <div className="text">
                      <h3>Suraj</h3>
                      <span>Business Development</span>
                      <ul className="social_media">
                        <li>
                          <a href="#">
                            <i className="icofont-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-3 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={400}
                >
                  <div className="experts_box">
                    <img src="images/user-icon.png" alt="image" />
                    <div className="text">
                      <h3>Kiran</h3>
                      <span>Marketing &amp; Sales</span>
                      <ul className="social_media">
                        <li>
                          <a href="#">
                            <i className="icofont-facebook" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-twitter" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icofont-instagram" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <GetInTouch />
        </>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
