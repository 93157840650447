import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <React.Fragment>
      {/* Footer-Section start */}

      <footer>
        <div className="top_footer" id="contact">
          {/* animation line */}
          <div className="anim_line dark_bg">
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
            <span>
              <img src="assets/images/anim_line.png" alt="anim_line" />
            </span>
          </div>
          {/* container start */}
          <div className="container">
            {/* row start */}
            <div className="row">
              {/* footer link 1 */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="abt_side">
                  <div className="logo">
                    <img src="images/white-logo.png" alt="image" />
                  </div>
                  <ul>
                    <li>
                      <a href="#">jamcpay@gmail.com</a>
                    </li>
                    <li>
                      <a href="#">+91 9852-5252-68</a>
                    </li>
                  </ul>
                  <ul className="social_media">
                    <li>
                      <a href="https://www.facebook.com/" target="_blank">
                        <i className="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/" target="_blank">
                        <i className="icofont-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/" target="_blank">
                        <i className="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a href="https://wa.me/+919852525268" target="_blank">
                        <i className="icofont-whatsapp" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* footer link 2 */}
              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Our Services</h3>
                  <ul>
                    <li>
                      <a href="our_services">Mobile Recharge</a>
                    </li>
                    <li>
                      <a href="our_services">DTH Recharge</a>
                    </li>
                    <li>
                      <a href="our_services">Google Play Recharge</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* footer link 3 */}
              <div className="col-lg-3 col-md-6 col-12">
                <div className="links">
                  <h3>Useful Links</h3>
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="about_us">About Us</a>
                    </li>
                    <li>
                      <a href="contact_us">Contact Us</a>
                    </li>
                    <li>
                      <a href="terms_and_conditions">Terms and Conditions</a>
                    </li>
                    <li>
                      <a href="privacy_policy">Privacy Policy</a>
                    </li>
                    <li>
                      <a href="refund_policy">Refund Policy</a>
                    </li>
                    <li>
                      <a href="grievance_policy">Grievance Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* footer link 4 */}
              <div className="col-lg-2 col-md-6 col-12">
                <div className="try_out">
                  <h3>Let’s Try Out</h3>
                  <ul className="app_btn">
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.fispl.jamcpay"
                        target="_blank"
                      >
                        <img
                          src="assets/images/googleplay_blue.png"
                          alt="image"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </div>
        {/* last footer */}
        <div className="bottom_footer">
          {/* container start */}
          <div className="container">
            {/* row start */}
            <div className="row">
              <div className="col-md-6">
                <p>Copyright © 2024 JAMC PAY</p>
              </div>
              <div className="col-md-6">
                <p className="developer_text">
                  Design &amp; developed by{" "}
                  <a href="https://www.formaxit.com" target="blank">
                    Formax IT Solutions
                  </a>
                </p>
              </div>
            </div>
            {/* row end */}
          </div>
          {/* container end */}
        </div>
        {/* go top button */}
        <div className="go_top">
          <span>
            <img src="assets/images/go_top.png" alt="image" />
          </span>
        </div>
      </footer>
      {/* Footer-Section end */}

      <div class="purple_backdrop"></div>
    </React.Fragment>
  );
};

export default Footer;
